.saveFileModal {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0px);
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.4s forwards;
}

@keyframes fadeIn {
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(10px);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}
.saveFileModalContainer {
  height: 100vh;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveFileModalContent {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 20%;
  margin-top: 5rem;
  justify-content: center;
  animation: scaleUp 0.4s forwards;
}
.saveFileModalHeader {
  height: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saveFileModalHeader h2 {
  margin-top: 0.3rem;
  color: white;
}
.saveFileModalBody {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.saveFileModalBody input {
  min-width: 84%;
  height: 55%;
  border-radius: 10px;
  border: none;
  text-indent: 1rem;
}

.saveFileModalButton {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.3rem;
}
.saveFileModalButton button {
  min-width: 40%;
  height: 70%;
  margin: 0 2%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  
}
.saveFileModalButton button:first-child{
    background-color: #00B4C4 ;
    color: white;
}

@media (max-width:600px) and (min-height:600px) {
  .saveFileModalContent{
    width: 100%;
    height:30%;
  }
}

@media  (max-width:500px) and (max-height:600px) {
  .saveFileModalContent{
    width: 100%;
    height:40%;
  }
}

@media  (min-width:500px) and (max-height:600px) {
  .saveFileModalContent{
    width: 70%;
    height:30%;
  }
}