.homeContainer {
  height: 100vh;
  padding: 0 4rem;
  display: flex;
  justify-content: center;
}
.homeContent {
  position: relative;
  top: 12rem;
  display: flex;
  width: 100%;
  height: 100%;
}

.cards {
  display: flex;
  flex-direction: row;
  align-items: top;
  width: 100%;
  
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column-reverse;
  margin: 5%;
  height: 30%;
  width: 30%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
}

.cardHeader {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
}
.cardHeader p {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}

.cardBody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cardBody p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: xx-large;
}
