.searchTableContainer {
  display: flex;
  justify-content: end;
  align-items: center;
}



.searchTableButton {
  width: 200px;
  height: 50px;
  margin: 0 10px 10px 0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  transition: ease-in .3s;
}
.searchTableButton:hover{
  background-color: #00b4c4;
  color: white;
}

.tableContainer {
  margin-top: 3rem;
  width: 100%;
  padding: 0 4rem;
  
}

.tableContent {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  width: auto;
  border-radius: 10px 10px 0 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.alper {
  border-radius: 10px;
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
}
.searchLocationTd {
  border-bottom: gray 1px solid !important;
}
.SearchLocationTh {
  height: 4rem !important;
  border-bottom: gray 4px solid;
  font-weight: bolder !important;
  font-size: 1.3rem !important;
}
.SearchLocationTh:first-child {
  width: 50px;
}
.SearchLocationTh,
.searchLocationTd {
  height: 4rem;
  width: 20%;
  color: white;
  height: 3rem;
  padding: 0rem 1rem;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-right: gray 1px solid;

  font-size: 1rem;
}
.SearchLocationTh:last-child,
.searchLocationTd:last-child {
  border-right: none;
}

.searchLocationTd:first-child {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchLocationTd input {
  width: 50px;
  height: 50px;
}
.searchLocationTd i {
  color: #00b4c4;
}

.searchLocationTd a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
}
.searchLocationTd a:visited {
  color: #00b4c4;
}

.Pagination {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0 0 1rem 0;
  padding: 1rem 0.8rem 0.8rem 0;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0 0 10px 10px;
}
.pageNumbersUl {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
}
.pageNumbersUl li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: white;
  padding: 0;
  border: 1px solid #00b4c4;
}
.paginationButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #00b4c4;
  background: none;
  cursor: pointer;
  color: white;
}

.paginationButton:hover,
.pageNumbersUl li:hover {
  background-color: #00b4c4;
  color: white;
}
.activePage {
  background-color: #00b4c4;
}

@media (max-width: 900px) {
  .Pagination {
    justify-content: flex-end;
    position: relative;
    right: 0;
  }

  .pageNumbersUl {
    display: flex;
    justify-content: flex-start;
  }
}
