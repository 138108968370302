.tooltipsContainer {
  position: relative;
  display: inline-block;
}
.tooltips {
  position: absolute;
  bottom: calc(100% + 10px);
  transform: translateX(-45%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 2;
  min-width: 300px;
  white-space: wrap;
}
.tooltips::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: -10px;
  left: 50.7%;
  transform: translateX(-50%);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.8);
}
/* .tooltips span {
  display: inline-block;
  max-width: 100%; 
  white-space: normal;
}
 */
