.dataContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(../images/mainBackground.jpg);
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  padding-top: 12rem;
}

.dataContent {
  width: 60%;
  height: 9rem;
  margin: 1.3rem;
  display: flex;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  color: white;
}
.dataContentNone{
  width: 60%;
  height: 9rem;
  margin: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  color: white;
}
.dataContent:last-child {
  margin-bottom: 2rem;
}

.deleteData {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  border-right: 1px solid black;
  cursor: pointer;
}
.dataBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  width: 80%;
  cursor: pointer;
}
.dataFileName{
  display: flex;
  height: 30%;
}
.dataFileName i {
  width: 5%;
  height: 100%;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.dataFileName div{
  width: 95%;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}

.dataAddress {
  height: 30%;
  display: flex;
}

.dataAddress i{
  width: 5%;
  height: 100%;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.dataAddress div{
  width: 95%;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
}
.dataDetail {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: white;
  cursor: pointer;
}
