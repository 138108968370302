.signupContainer {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-image: url(../images/mainBackground.jpg);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signupContent {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 40%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  -webkit-box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
  z-index: 1;
}

.signupRightContent {
  width: 100%;
  height: 100%;
}
.signupRightContentHeader {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  align-items: center;
  font-size: 4rem;
  color: white;
  font-family: "Courgette", cursive;
  -webkit-text-stroke-width: 0.1;
  -webkit-text-stroke-color: black;
}
.signupRightContentHeader img {
  width: auto;
  height: 3.5rem;
  margin-right: 1rem;
}

.signupRightContentBody {
  width: 100%;
  height: 80%;
}
.signupField {
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signupField input {
  width: 70%;
  min-height: 4rem;
  justify-items: auto;
  border-radius: 1rem;
  border: none;
  margin: 1.3rem 1rem;
  padding: 0.4rem;
  position: relative;
  text-indent: 1rem;
  font-size: 1.2rem;
}
.signupField input::placeholder {
  font-size: 1.2rem;
}
.signupBtnCover {
  height: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 20px;
}
.signupBtn {
  font-size: 1.5rem;
  background-color: white !important;
  width: 150px;
  height: 50px;
  min-height: 40px;
  min-width: 100px;
  position: relative;
  border-radius: 10px;
  border: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.signupBtn::before,
.signupBtn::after {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: -100%;
  background: rgb(13, 90, 110) !important;
  z-index: -1;
  transition: all 0.4s;
}

.signupBtn:hover {
  color: white !important;
}

.signupBtn::before {
  opacity: 0.3;
}
.signupBtn::after {
  transition-delay: 0.3s;
}

.signupBtn:hover::before,
.signupBtn:hover::after {
  left: 0;
  width: 100%;
}

.signupContact {
  height: 30%;
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signupContact:hover{
    color: white !important;
}
.signupContact span{
    margin-left: 0.4rem;
}
.signupContact span:hover{
color: #00b4c4;
}
.error {
  font-size: 1.4rem;
  font-weight: 600;
  color: red;
}
  @media (max-width: 1450px) {
    .signupRightContentHeader {
      font-size: 2rem;
      padding-top: 0;
    }
    .signupRightContentHeader img {
      height: 2rem;
    }
  }
  
  @media (max-width: 800px) {
    .signupLeftContent {
      width: 0;
    }
  
    .signupRightContentHeader {
      font-size: 2rem;
    }
    .signupRightContentHeader img {
      height: 2rem;
    }
  }
  @media (max-width: 620px) {
    .signupContent {
      width: 100%;
    }
  }
  
  @media (max-height: 600px) {
    .signupContent {
      height: 100%;
    }
  }
  
