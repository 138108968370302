html {
  scroll-behavior: smooth;
}

.hompageContainer {
  height: auto;
  min-height: 200vh;
  background-image: url(../images/mainBackground.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
.homepageContainer {
  height: 100vh;
  /*  background-image: url(../images/mainBackground.jpg);
  background-size: cover; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepageContent {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  width: 60%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 4rem;
}
.homepageHeader {
  width: 100%;
  font-size: 5rem;
  font-weight: bolder;
  color: white;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homepageHeader span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.homepageButtons {
  width: 80%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepageButtons-button {
  width: 30%;
  height: 50%;
  margin: 0 2rem;
  border: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .homepageHeader {
    font-size: 3rem;
    height: 50%;
  }
  .homepageContent {
    width: 100%;
  }
  .homepageButtons {
    height: 50%;
    flex-direction: column;
  }
  .homepageButtons-button {
    margin: 1rem 0;
    width: 60%;
  }
}

@media (min-width: 1000px) and (max-height: 600px) and (max-width: 1920px) {
  .homepageContent {
    width: 80%;
  }
  .homepageHeader span {
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homepageButtons {
    height: 40%;
  }
}
