.locationSearchContainer {
  width: 100%;
  height: 100%;
}

.locationSearchCoordinates {
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationSearchCoordinates input {
  width: 48%;
  height: 3rem;
  border: none;
  border-radius: 10px;
  margin: 10px 1%;
  text-indent: 0.5rem;
}

.locationSearchDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.locationSearchDetail input {
  width: 48%;
  height: 3rem;
  border: none;
  border-radius: 10px;
  margin: 10px 1%;
  text-indent: 0.5rem;
}
.locationSearchButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationSearchButton button {
  width: 30%;
  height: 3rem;
  border: none;
  border-radius: 10px;
  margin: 10px 1%;
  text-indent: 0.5rem;
  background-color: #00b4c4;
  color: white;
  cursor: pointer;
}
.mapsLocationContainer {
  width: 98%;
  height: 100%;
  display: flex;
  justify-content: end;
}
.mapsLocation {
  text-decoration: none;
  color: white;
  font-size: 16px;
}
.mapsLocation i {
  font-size: 12px;
}
