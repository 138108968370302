.resetPasswordContainer {
    width: 100%;
    height: 100vh;
    background-image: url(../images/mainBackground.jpg);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resetPasswordContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 50%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    -webkit-box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 14px 26px -10px rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  .resetPasswordHeader {
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resetPasswordHeader p {
    font-size: 2rem;
    font-weight: bold;
    color: white;
  }
  .resetPasswordBody {
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .resetPasswordContent input {
    width: 70%;
    height: 4rem;
    border-radius: 1rem;
    border: none;
    margin: 1.3rem 1rem;
    padding: 0.4rem;
    position: relative;
    text-indent: 1rem;
    font-size: 1.2rem;
  }
  .resetPasswordContent button {
    border: none;
    width: 30%;
    height: 3rem;
    border-radius: 10px;
    color: white;
    background-color: #00b4c4;
    font-size: 1rem;
    cursor: pointer;
  }
  @media (max-width: 500px)or (max-height: 500px) {
    .resetPasswordContent {
      width: 100%;
      height: 70%;
      border-radius: 0;
    }
  }
    