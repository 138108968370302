.registerUserContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.registerUserContent {
  position: relative;
  display: flex;
  width: 100%;
  height: 70%;
  margin-top: 5rem;
  justify-content: center;
}

.registerUserContent .registerUserCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 40%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
  flex-wrap: wrap;
  animation: scaleUp 0.4s forwards;
  cursor: default;
  justify-content: center;
}

.registerUserCard .registerUserCardHeader {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 20%;
  align-items: center;
  font-size: large;
  margin: 0px;
  height: 10%;
}

.registerUserCard .registerUserCardBody {
  display: flex;
  height: 80%;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.registerUserCard .registerUserCardBody input {
  margin: 20px;
  width: 70%;
  height: 40px;
  text-align: center;
}

.registerUserCard .registerUserCardBody button {
  width: 70%;
  height: 40px;
  text-align: center;
  background-color: #00b4c4;
  margin-top: 40px;
}
